<template>
  <div>
    <v-container>
      <v-row class="justify-space-around">
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card>
            <v-img :src="truck.image"> </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs
                    class="ma-0"
                    v-model="tab"
                    background-color="transparent"
                  >
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions >
                    <v-btn class="primary" @click="truckname=truck.name ; enquire()">Enquire</v-btn>
                    <v-spacer/>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';
export default {
  data: () => ({
    truckname: '',
    trucks: [
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-2523-TC-6X4-Freight-Carrier.webp?alt=media&token=08512f70-f6be-4a86-89c9-68e1eebdf06a',
        name: "LPT 2523 TC 6X4 Freight Carrier (14 ton)",
        enginemake: "TATA Cummins",
        enginemodel: "B235 20 (Euro 2)",
        capacity: "5883",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "170 @ 2500",
        torque: "814 @ 1500",
        transmissionm: "ZF – 9S1110",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "9.48",
        topgear: "0.75",
        height: "2800",
        length: "9370",
        width: "2448",
        frontoverhang: "1515",
        rearoverhang: "2170",
        wheelbase: "4880",
        clearance: "236",
        ca: "4055",
        gvm: "24000",
        v: "24000",
        gcm: "31000",
        dt: "31000",
        ppayload: "16620",
        tare: "7380",
        prear: "18000",
        pfront: "6000",
        manurear: "19000",
        manufront: "6000",
        clutch: "Single",
        diameter: "380",
        reductype: "Single",
        finalr: "6.17",
        brakestype: "	Full air dual",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        fuel: "350",
        tyresize: "11.00R22.5 – 16PR",
      },
      
    ],
    tab: null,
    //
  }),
  methods: {
    enquire() {
      if (this.truckname != "") {
        router.push("/contact");
        this.$store.state.enquiryname = this.truckname;
        console.log(this.$store.state.enquiryname);
      }
    },
  },
};
</script>

<style></style>
