<template>
  <div>
    <v-dialog v-model="isLoading"></v-dialog>
    <section>
      <v-container>
        <v-carousel
          style="border-radius: 16px"
          height="80vh"
          cycle
          hide-delimiter-background
          show-arrows-on-hover
        >
          <v-carousel-item
            eager
            v-for="(carouselImage, i) in carouselImages"
            :to="carouselImage.url"
            :key="i"
            ><v-img
              eager
              height="100%"
              class="flex"
              cover
              :src="carouselImage.src"
            >
              <v-container class="fill-height">
                <v-row align="center" justify="center" class="fill-height">
                  <v-col sm="6" cols="12">
                    <v-card
                      v-if="carouselImage.text2 != ''"
                      dark
                      elevation="0"
                      style="background-color: rgba(255, 255, 255, 0)"
                      max-width="100%"
                      class="text-left ml-sm-16 ml-8"
                      ><v-card-title
                        v-scrollanimation
                        class="font-weight-black display-2 hidden-sm-and-down"
                        style="
                          word-break: break-word;
                          text-shadow: 2px 2px 8px #000000;
                        "
                        >{{ carouselImage.text2 }}</v-card-title
                      >
                      <v-card-title
                        v-scrollanimation
                        class="font-weight-black display-1 hidden-md-and-up"
                        style="
                          word-break: break-word;
                          text-shadow: 2px 2px 8px #000000;
                        "
                        >{{ carouselImage.text2 }}</v-card-title
                      >
                    </v-card>
                  </v-col>
                </v-row></v-container
              ></v-img
            ></v-carousel-item
          >
        </v-carousel>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row style="min-height: 65vh" justify="center" align="center">
          <v-col cols="12" md="6">
            <v-card-title
              v-scrollanimation
              class="text-center display-1 font-weight-black primary--text ml-md-4"
              style="word-break: break-word"
              >About Tata | North West</v-card-title
            >

            <v-card-text class="text-left">
              <ul>
                <li v-scrollanimation>
                  Tata North-West was founded in late 2021 and has taken all the
                  experience and expertise from other branches in South Africa
                  and create a conglomerate of all the postives we could take
                  out of it
                </li>
                <li class="mt-2" v-scrollanimation>
                  Tata Truck and Bus North-West is a franchise of the mother
                  company Tata Motors International and has brought the Tata
                  Truck and Bus Range to the North-West province of South Africa
                </li>
                <li class="mt-2 font-weight-bold" v-scrollanimation>
                  A new business full of potential situated in the heart of the
                  Klerksdorp industrial area, Tata North West pushes for the top
                  quality parts and performance everyone expects from a well
                  renown company and strives to bring in only the best service
                  and products to it's valued clients. Tata North West in
                  Klerksdorp is here to stay, with or without competition.
                </li>
              </ul>
            </v-card-text>
          </v-col>
          <v-col cols="12" md="6">
            <v-img
              eager
              contain
              class="mx-auto"
              max-height="256px"
              src="https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/logo1.png?alt=media&token=bb3869f8-cc1f-4062-a788-9b7eff343e4c"
            ></v-img>
          </v-col>
        </v-row>
      </v-container>
    </section>
    <section>
      <v-container>
        <v-row style="min-height: 50vh" class="mb-8">
          <v-col
            v-for="(model, i) in models"
            :key="i"
            cols="12"
            sm="6"
            md="4"
            lg="3"
          >
            <v-hover>
              <template v-slot:default="{ hover }">
                <v-card
                  height="100%"
                  dark
                  class="mx-auto my-1"
                  max-width="100%"
                >
                  <v-img
                    aspect-ratio="1.5"
                    eager
                    cover
                    height="100%"
                    class="justify-end align-end text-center"
                    :src="model.image"
                    ><v-card-text
                      style="text-shadow: 2px 2px 8px #000000"
                      class="headline font-weight-black"
                      >{{ model.title }}</v-card-text
                    ></v-img
                  >

                  <v-fade-transition>
                    <v-overlay v-if="hover" absolute color="primary">
                      <v-btn :to="model.link" color="secondary"
                        >See Range</v-btn
                      >
                    </v-overlay>
                  </v-fade-transition>
                </v-card>
              </template>
            </v-hover>
          </v-col>
        </v-row>
      </v-container>
    </section>
  </div>
</template>

<script>
export default {
  name: "HelloWorld",

  data: () => ({
    isLoading: true,
    carouselImages: [
      {
        text2: "A vehicle solution for every customers needs",
        url: "/models/primarange",
        text: "Prima Range",
        src: "https://firebasestorage.googleapis.com/v0/b/aeteratoken.appspot.com/o/toroNW2.jpg?alt=media&token=b3a95cfe-0690-442c-a0cd-970b1819fed4",
      },
      {
        text2:
          "Situated in Klerksdorp, in the central industrial suburb for access to ammenities and close to the highway",
        url: "/aftersales",
        text: "Bus Range",
        src: "https://firebasestorage.googleapis.com/v0/b/aeteratoken.appspot.com/o/toroNW1.jpg?alt=media&token=ccab249b-9c61-47cb-8ea8-6390fe19ee85",
      },
      {
        text2:
          "Take your business to the next level with our commercial vehicles",
        url: "/models/medcom",
        text: "Medium Commercial",
        src: "https://firebasestorage.googleapis.com/v0/b/aeteratoken.appspot.com/o/toroNW6.jpg?alt=media&token=d6c004eb-abc3-4bb4-8f62-2af32696cdf4",
      },
      {
        text2: "See our range of trucks and busses",
        url: "/models",
        text: "Medium Commercial",
        src: "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/buildingoffice%2Fbanners%2Fbanner3.jpg?alt=media&token=e4727a5d-9579-4a39-b72e-9a78faaa52c7",
      },
    ],
    models: [
      {
        title: "Medium Commercial",
        link: "/models/medcom",
        image:
          "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2F715-4x4-Web-Banners-700X500_.webp?alt=media&token=8ad0ca59-0828-436e-95db-2238e0148483",
      },
      {
        title: "Heavy Commercial",
        link: "/models/heavycom",
        image:
          "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-1518_640x360_Without.webp?alt=media&token=b2cf1635-beb9-4493-851b-2f4d46eb6697",
      },
      {
        title: "Extra Heavy Commercial",
        link: "/models/eheavycom",
        image:
          "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-2523-TC-6X4-Freight-Carrier.webp?alt=media&token=08512f70-f6be-4a86-89c9-68e1eebdf06a",
      },
      {
        title: "Prima Range",
        link: "/models/primarange",
        image:
          "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2Fprima-LX-25T-gallery-03.webp?alt=media&token=ad004aa8-9b77-4c9e-88d1-98996ed6dfd2",
      },
    ],
  }),
  mounted() {
    this.isLoading = false;
  },
};
</script>

<style scoped>
.before-enter {
  opacity: 0;
  transform: translateY(100px);
  transition: all 2s ease-out;
}

.enter {
  opacity: 1;
  transform: translateY(0px);
}
</style>
