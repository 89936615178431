<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card>
            <v-img :src="truck.image"> </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                  >
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card color="basil" flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions >
                    <v-btn class="primary" @click="truckname=truck.name ; enquire()">Enquire</v-btn>
                    <v-spacer/>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';
export default {
  data: () => ({
    truckname: '',
    trucks: [
      {
        name: "LPK 813 EX2 – 4X2 – 4ton Tipper",
        image: "https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-813-EX2-4X2-Freight-Carrier.webp?alt=media&token=b016cd06-1249-44fd-a12a-ffd0d0103483",
        enginemake: "TATA",
        enginemodel: "697 TCIC (Euro 2)",
        capacity: "5675",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "95 @ 2400",
        torque: "416 @ 1400 - 1700",
        transmissionm: "GBS 40",
        transmissiont: "Synchromesh",
        shift: "Manual",
        gears: "5",
        firstgear: "7.51",
        topgear: "1",
        height: "2455",
        length: "5780",
        width: "2155",
        frontoverhang: "1273",
        rearoverhang: "1450",
        wheelbase: "3400",
        ca: "3108",
        gvm: "8050",
        v: "8050",
        gcm: "10050",
        dt: "10050",
        ppayload: "3970",
        tare: "4080",
        prear: "5060",
        pfront: "2900",
        manurear: "5060",
        manufront: "2900",
        clutch: "Single",
        diameter: "310",
        reductype: "single",
        finalr: "3.44",
        brakestype: "Full air dual",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        fuel: "120",
        tyresize: "7.50R16-14PR",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-813-EX2-4X2-Freight-Carrier.webp?alt=media&token=b016cd06-1249-44fd-a12a-ffd0d0103483',
        name: "LPT 813 EX2 4X2 Freight Carrier (4 ton)",
        enginemake: "TATA",
        enginemodel: "697 TCIC (Euro 2)",
        capacity: "5675",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "95 @ 2400",
        torque: "416 @ 1400 - 1700",
        transmissionm: "GBS 40",
        transmissiont: "Synchromesh",
        shift: "Manual",
        gears: "5",
        firstgear: "7.51",
        topgear: "1",
        height: "2341",
        length: "6860",
        width: "2155",
        frontoverhang: "1275",
        rearoverhang: "2100",
        wheelbase: "3800",
        ca: "3475",
        gvm: "8050",
        v: "8050",
        gcm: "10050",
        dt: "10050",
        ppayload: "4990",
        tare: "3060",
        prear: "5060",
        pfront: "2900",
        manurear: "5060",
        manufront: "2990",
        clutch: "single",
        diameter: "310",
        reductype: "single",
        finalr: "3.44",
        brakestype: "Full air dual",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        fuel: "120",
        tyresize: "7.50R16-14PR",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2F715-4x4-Web-Banners-700X500_.webp?alt=media&token=8ad0ca59-0828-436e-95db-2238e0148483',
        name: "LPTA 715 4X4 Freight Carrier (3ton)",
        enginemake: "TATA",
        enginemodel: "6BTAA 5.9TC (Euro 3)",
        capacity: "5883",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "108 @ 2500",
        torque: "590 @ 1500",
        transmissionm: "GBS 600",
        transmissiont: "Synchromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "2850",
        length: "5616",
        width: "2145",
        frontoverhang: "1431",
        rearoverhang: "960",
        wheelbase: "3225",
        ca: "3091",
        gvm: "7650",
        v: "7750",
        gcm: "10250",
        dt: "10250",
        ppayload: "3310",
        tare: "4340",
        prear: "4000",
        pfront: "3750",
        manurear: "4000",
        manufront: "3750",
        clutch: "Single",
        diameter: "352",
        reductype: "Single",
        finalr: "5.857",
        brakestype: "Full air dual",
        bfront: "Drums",
        brear: "Drums",
        antilock: "No",
        fuel: "200",
        tyresize: "7.50R16-14PR",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FUltra-814.webp?alt=media&token=ee14feb0-3f63-4eec-b08a-2e1147f63cd9',
        name: "Tata Ultra 814 – 4 ton",
        enginemake: "TATA",
        enginemodel: "New Gen Common Rail",
        capacity: "2956",
        layout: "Longditudal",
        enginetype: "Diesel – Common rail – turbo intercooled.",
        powerkw: "100kw @ 2500",
        torque: "390 @ 1500",
        transmissionm: "Tata G550",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.9:1",
        topgear: "0.84:1",
        height: "2464",
        length: "7058",
        width: "2400",
        frontoverhang: "1275",
        rearoverhang: "1863",
        wheelbase: "3920",
        ca: "3470",
        gvm: "8750",
        v: "8750",
        gcm: "10750",
        dt: "10750",
        ppayload: "5510",
        tare: "3240",
        prear: "5500",
        pfront: "3250",
        manurear: "5500",
        manufront: "3250",
        clutch: "Single",
        diameter: "330",
        reductype: "",
        finalr: "",
        brakestype: "	Dual Circuit Air.",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        fuel: "120",
        tyresize: "225/75R17.5",
      },
    ],
    tab: null,
    //
  }),
  methods: {
    enquire() {
      if (this.truckname!='') {
        router.push('/contact')
        this.$store.state.enquiryname = this.truckname
        console.log(this.$store.state.enquiryname)
        }

    
    }
  },
};
</script>

<style></style>
