<template>
  <div>
    <v-container>
      <v-row class="justify-space-around">
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card>
            <v-img :src="truck.image"> </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs v-model="tab" background-color="transparent">
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card color="basil" flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-btn class="primary" @click="truckname=truck.name ; enquire()">Enquire</v-btn>
                    <v-spacer/>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';
export default {
  data: () => ({
    truckname: '',
    trucks: [
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FDaewoo%20K5DEF%20%E2%80%93%2010%20cube%20tipper.webp?alt=media&token=413ed009-f741-45ef-9581-fbcde568ca91',
        name: "Daewoo K5DEF – 10 cube tipper",
        enginemake: "Doosan",
        enginemodel: "DE12TIS",
        capacity: "11051",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "250 @ 2100",
        torque: "1421 @ 1260",
        transmissionm: "Tata – T15S6",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "7.263",
        topgear: "0.699",
        height: "3060",
        length: "7775",
        width: "2495",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "4880",
        ca: "3700",
        clearance: "",
        gvm: "25500",
        v: "",
        gcm: "43500",
        dt: "43500",
        ppayload: "",
        tare: "11690 (Including body)",
        prear: "18000",
        pfront: "7500",
        manurear: "26000",
        manufront: "7500",
        clutch: "Single",
        diameter: "430",
        reductype: "Single",
        finalr: "6.166",
        brakestype: "Full dual air",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        EngineBreak: "No",
        fuel: "400",
        tyresize: "315/80R22.5",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FDaewoo%20Mixer.webp?alt=media&token=03d92089-6d55-4f86-ab86-917a067c091c',
        name: "Daewoo K5MVF – 6 cube mixer",
        enginemake: "Doosan",
        enginemodel: "DV15TIS",
        capacity: "14618",
        layout: "V8",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "287 @ 2100",
        torque: "1569 @ 1200",
        transmissionm: "Tata - T16S6",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "7.263",
        topgear: "0.699",
        height: "3720",
        length: "8304",
        width: "2495",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "4880",
        ca: "3080",
        clearance: "",
        gvm: "25500",
        v: "",
        gcm: "43500",
        dt: "33500",
        ppayload: "",
        tare: "11760",
        prear: "18000",
        pfront: "7500",
        manurear: "26000",
        manufront: "7500",
        clutch: "Single",
        diameter: "430",
        reductype: "Single",
        finalr: "6.143",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "No",
        fuel: "300",
        tyresize: "315/80R22.5",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FDaewoo%20K7CEF%20%E2%80%93%206%C3%974%20Freight%20Carrier.webp?alt=media&token=b8f37155-5085-47cd-818b-62a347841e6f',
        name: "Daewoo K7CEF – 6×4 Freight Carrier",
        enginemake: "Doosan",
        enginemodel: "DE12TIS",
        capacity: "11051",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "250 @ 2100",
        torque: "1421 @ 1260",
        transmissionm: "Tata – T14S10 – with provision for PTO",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "10",
        firstgear: "9.48",
        topgear: "0.75",
        height: "2800",
        length: "9985",
        width: "2495",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "5800",
        ca: "4430",
        clearance: "",
        gvm: "25000",
        v: "",
        gcm: "56000",
        dt: "",
        ppayload: "",
        tare: "7380",
        prear: "18000",
        pfront: "6000",
        manurear: "23000",
        manufront: "6000",
        clutch: "Single",
        diameter: "430",
        reductype: "Single",
        finalr: "5.571",
        brakestype: "Dual Circuit air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "No",
        fuel: "400",
        tyresize: "315/80R22.5",
      },
    ],
    tab: null,
    //
  }),
  methods: {
    enquire() {
      if (this.truckname!='') {
        router.push('/contact')
        this.$store.state.enquiryname = this.truckname
        console.log(this.$store.state.enquiryname)
        }

    
    }
  },
};
</script>

<style></style>
