<template>
  <div>
    <v-container>
      <v-row>
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card >
            <v-img :src="truck.image">
            </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs
                    v-model="tab"
                    background-color="transparent"
                    color="basil"
                    grow
                  >
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card color="basil" flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                            <tr>
                              <td>Engine Brake :</td>
                              <td>{{ truck.EngineBrake }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-btn
                      class="primary"
                      @click="
                        truckname = truck.name;
                        enquire();
                      "
                      >Enquire</v-btn
                    >
                    <v-spacer />
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from "@/router";
export default {
  data: () => ({
    truckname: "",
    trucks: [
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-1216_640x390-without.webp?alt=media&token=fed9e09a-e3ec-4ea2-bc3f-a5969be593d4',
        name: "LPT 1216 4X2 Freight Carrier (6 ton)",
        enginemake: "TATA",
        enginemodel: "	697 TCIC (Euro 2)",
        capacity: "5675",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "118 @ 2400",
        torque: "550 @ 1500",
        transmissionm: "GBS 600",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "2473",
        length: "8335",
        width: "2270",
        frontoverhang: "1275",
        rearoverhang: "2285",
        wheelbase: "4830",
        ca: "3875",
        gvm: "11500",
        v: "11500",
        gcm: "14900",
        dt: "14900",
        ppayload: "7490",
        tare: "4010",
        prear: "7500",
        pfront: "4000",
        manurear: "7500",
        manufront: "4000",
        clutch: "Single",
        diameter: "352",
        reductype: "Single",
        finalr: "5.285",
        brakestype: "Full air dual",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        fuel: "160",
        tyresize: "8.25R20 – 14PR",
      },
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-1518_640x360_Without.webp?alt=media&token=b2cf1635-beb9-4493-851b-2f4d46eb6697',
        name: "LPK 1518 4×2 Tipper",
        enginemake: "TATA Cummins",
        enginemodel: "B 180 20 (Euro 2)",
        capacity: "5883",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "131 @ 2500",
        torque: "650 @ 1500",
        transmissionm: "GBS 750",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "2970",
        length: "6595",
        width: "2440",
        frontoverhang: "1515",
        rearoverhang: "1500",
        wheelbase: "3580",
        ca: "3017",
        gvm: "14900",
        v: "14900",
        gcm: "21400",
        dt: "21400",
        ppayload: "7730",
        tare: "7170",
        prear: "9000",
        pfront: "6000",
        manurear: "9000",
        manufront: "6000",
        clutch: "Single",
        diameter: "380",
        reductype: "Single",
        finalr: "5.857",
        brakestype: "Full air dual",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        fuel: "225",
        tyresize: "11.00R22.5 – 16PR",
      },
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-1518_640x360_Without.webp?alt=media&token=b2cf1635-beb9-4493-851b-2f4d46eb6697',
        name: "LPT 1518 EX2 4X2 Freight Carrier (8 ton)",
        enginemake: "TATA Cummins",
        enginemodel: "B 180 20 (Euro 2)",
        capacity: "5883",
        layout: "Diesel inline 6",
        enginetype: "Turbo intercooled",
        powerkw: "132 @ 2500",
        torque: "650 @ 1500",
        transmissionm: "GBS 750",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "2800",
        length: "9047",
        width: "2440",
        frontoverhang: "1515",
        rearoverhang: "2337",
        wheelbase: "5195",
        ca: "4642",
        gvm: "14900",
        v: "14900",
        gcm: "21400",
        dt: "21400",
        ppayload: "9680",
        tare: "5220",
        prear: "9000",
        pfront: "6000",
        manurear: "9000",
        manufront: "6000",
        clutch: "Single",
        diameter: "380",
        reductype: "Single",
        finalr: "5.857",
        brakestype: "Full air dual",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        fuel: "350",
        tyresize: "11.00R22.5 – 16PR",
      },
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPT-1623-Web-Banners-650X290_.webp?alt=media&token=5ab44627-5370-4370-92de-336275bd342b',
        name: "LPT 1623 – 4×2 Freight Carrier",
        enginemake: "TATA Cummins",
        enginemodel: "B5.9 E-III",
        capacity: "5883",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "154 @ 2500",
        torque: "789 @ 1500",
        transmissionm: "Eaton",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "7.05",
        topgear: "0.78",
        height: "2877",
        length: "9138",
        width: "2474",
        frontoverhang: "1515",
        rearoverhang: "2337",
        wheelbase: "5195",
        ca: "NA",
        gvm: "16000",
        v: "16000",
        gcm: "22500",
        dt: "22500",
        ppayload: "",
        tare: "5500",
        prear: "9000",
        pfront: "7000",
        manurear: "7000",
        manufront: "9000",
        clutch: "Single",
        diameter: "380",
        reductype: "Single",
        finalr: "5.857",
        brakestype: "Full dual circuit air",
        bfront: "Air",
        brear: "Air",
        antilock: "Standard",
        EngineBrake: "No",
        fuel: "350",
        tyresize: "295/80R 22.5 – Tubeless Radial",
      },
    ],
    tab: null,

    //
  }),
  methods: {
    enquire() {
      if (this.truckname != "") {
        router.push("/contact");
        this.$store.state.enquiryname = this.truckname;
        console.log(this.$store.state.enquiryname);
      }
    },
  },
};
</script>

<style></style>
