<template>
  <v-app>
    <v-app-bar
      height="96px"
      app
      fixed
      style="background-color: white"
      color="white"
      elevate-on-scroll
    >
      <v-container>
        <v-app-bar elevation="0" color="transparent">
          <div class="d-flex align-center">
            <router-link to="/">
              <v-img
                alt="Vuetify Logo"
                class="shrink mr-2"
                contain
                src="@/assets/logo.png"
                transition="scale-transition"
                width="80"
              />
            </router-link>
            <router-link to="/">
              <v-img
                alt="Vuetify Name"
                class="shrink mt-1 hidden-sm-and-down"
                contain
                min-width="120"
                src="@/assets/logotext.png"
                width="120"
              ></v-img>
            </router-link>
          </div>
          <v-card-title
            color="secondary"
            class="headline hidden-md-and-down primary--text"
            style="
              font-weight: 900 !important ;
              text-overflow: clip;
              overflow: visible;
            "
          >
            Truck and Bus North-West
          </v-card-title>
          <v-card-title
            color="secondary"
            class="headline hidden-lg-and-up"
            style="font-weight: 900 !important ; text-overflow: none"
          >
            NW
          </v-card-title>

          <v-spacer></v-spacer>
          <template>
            <v-btn class="hidden-sm-and-down" color="primary" small to="/" text
              >Home</v-btn
            >
            <!-- Models Menu -->
            <v-menu close-on-click open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  class="hidden-sm-and-down"
                  to="/models"
                  text
                  small
                  color="primary"
                  dark
                  v-bind="attrs"
                  v-on="on"
                >
                  Models
                </v-btn>
              </template>
              <v-list class="ma-0">
                <v-list-item
                  class="mx-0 my-0"
                  v-for="(item, index) in items"
                  :key="index"
                >
                  <v-btn
                    :to="item.url"
                    small
                    class="ma-0"
                    text
                    color="primary"
                    >{{ item.title }}</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn
              class="hidden-sm-and-down"
              color="primary"
              small
              to="/aftersales"
              text
              >After-sales</v-btn
            >

            <v-btn
              class="hidden-sm-and-down"
              color="primary"
              small
              to="/usedvehicles"
              text
              disabled
              >Used Vehicles</v-btn
            >
            <v-btn
              class="hidden-sm-and-down"
              color="primary"
              small
              to="/promotions"
              text
              >Promotions</v-btn
            >

            <v-btn
              class="ml-1 hidden-sm-and-down"
              small
              color="secondary"
              to="/contact"
              >Contact</v-btn
            >
          </template>
          <v-app-bar-nav-icon
            @click="navmenu = true"
            class="hidden-sm-and-up"
          ></v-app-bar-nav-icon>
        </v-app-bar>
      </v-container>
    </v-app-bar>
    <v-navigation-drawer app v-model="navmenu">
      <v-list-item>
        <v-list-item-avatar>
          <v-img src="@/assets/logo.png"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>Tata | Northwest</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-divider />
      <v-list>
        <v-list-item v-for="item in links" :key="item.title">
          <v-list-item-content>
            <v-btn
              :disabled="item.disabled"
              text
              class="primary text-left"
              :to="item.url"
              >{{ item.title }}</v-btn
            >
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main>
      <v-fade-transition mode="out-in">
        <router-view />
      </v-fade-transition>
    </v-main>

    <v-footer class="py-4" color="primary" padless>
      <v-row justify="center" no-gutters class="mt-4">
        <v-btn
          v-for="link in links"
          :key="link"
          color="white"
          small
          text
          :to="link.url"
          :disabled="link.disabled"
          rounded
        >
          {{ link.title }}
        </v-btn>
      </v-row>
      <v-col cols="12">
        <v-card
          class="mx-2 text-center"
          dark
          style="background-color: rgba(0, 0, 0, 0)"
          elevation="0"
        >
          <span class="my-0 font-weight-black headline">Contact Details</span>
          <br />
          <span class="white--text my-0 body-2">
            <span class="font-weight-bold">Phone Number</span> : 018 469 2146
          </span>
          <br />
          <span class="white--text my-0 body-2">
            <span class="font-weight-bold">Address</span> : 12 Neutron Street,
            Uraniaville, Klerksdorp
          </span>
        </v-card>
      </v-col>
    </v-footer>
    <template>
      <v-dialog persistent v-model="promotionDialog">
        <v-card color="white">
          <v-container class="pa-md-8">
            <v-img
              class="hidden-sm-and-down"
              contain
              max-height="500px"
              src="@/assets/promotionalpic.png"
            ></v-img
            >
            <v-img
              class="hidden-md-and-up"
              contain
              max-height="500px"
              src="@/assets/promotionalmobile.png"
            ></v-img>
            <!-- <v-card-text class="display-1 text-center"
              >The all new Tata Ultra T4 Truck is now in South
              Africa</v-card-text
            > -->
            <v-divider class="my-4"></v-divider>
            <v-card-text class="headline">Truck Specifications</v-card-text>
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th class="text-left">Specification</th>
                    <th class="text-left">Amount</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in desserts" :key="item.name">
                    <td width="50%">{{ item.name }}</td>
                    <td>{{ item.calories }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-card-text class="headline"
              >Tata Ultra T4 Truck Highlights</v-card-text
            >
            <v-card-text class="body-1"
              >Tata Ultra T4 is a truck with gross vehicle weight of 10400 kg.
              The engine powering this Tata truck is 3000 cc NG 3L DICR,
              producing 138 HP of power and 360 Nm of maximum torque. The engine
              of Tata Ultra T4 truck is coupled with a 6 speed manual (6F+1R),
              Dashboard mounted gear lever gearbox offering superior mileage.
              This Tata Ultra T4 truck comes in 4x2 axle configuration. The
              wheelbase of 3310 wb, 3920 wb, 4530 wb, allows this Tata truck to
              offer load body length suitable for different kinds of
              applications. Air Brakes offer superior safety in Tata Ultra T4
              and parabolic leaf spring, with telescopic shock absorbers
              suspension in front offers good ride quality. More details of the
              Tata Ultra T4 truck features like mileage, fuel tank capacity,
              tyre, suspension, photos, etc are available in the sections above.
              It competes with other vehicles in the segment like SML Isuzu
              Samrat GS 10.7T BS6 Truck and Mahindra Furio 11 BS6 Truck
              .</v-card-text
            >
          </v-container>
          <v-card-actions class="pb-12">
            <v-spacer></v-spacer>
            <v-btn x-large @click="enquire" class="success">Enquire</v-btn>
            <v-spacer></v-spacer>
            <!-- <v-btn class="red white--text" @click="promotionDialog = false"
              >Close</v-btn
            > -->
          </v-card-actions>
          <v-btn
            @click="promotionDialog = false"
            color="red"
            fab
            dark
            top
            right
            fixed
          >
            <v-icon color="white">mdi-close</v-icon>
          </v-btn>
        </v-card>
      </v-dialog>
    </template>
  </v-app>
</template>

<script>
import router from "@/router";
export default {
  name: "HomePage",
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Tata Northwest Bus and Truck",
    // all titles will be injected into this template
    titleTemplate:
      "Tata NW | A vehicle solution for every customers needs | tatanorthwest.co.za",
  },

  data: () => ({
    promotionDialog: true,
    navmenu: false,
    items: [
      { title: "Medium Commercial", url: "/models/medcom" },
      { title: "Heavy Commercial", url: "/models/heavycom" },
      { title: "Extra Heavy Commercial", url: "/models/eheavycom" },
      { title: "Prima Range", url: "/models/primarange" },
      { title: "Bus Range", url: "/models/busrange" },
      { title: "Daewoo Range", url: "/models/daewoorange" },
    ],
    links: [
      { title: "Home", url: "/", disabled: false },
      { title: "Models", url: "/models", disabled: false },
      { title: "After-Sales", url: "/aftersales", disabled: false },
      { title: "Promotions", url: "/promotions", disabled: true },
      { title: "Used Vehicles", url: "/usedvehicles", disabled: true },
      { title: "Contact Us", url: "/contact", disabled: false },
    ],
    desserts: [
      {
        name: "GVW (Kg)",
        calories: "10400 Kg",
      },
      {
        name: "Engine",
        calories: "NG 3L DICR",
      },
      {
        name: "Power",
        calories: "138 HP@3000 rpm",
      },
      {
        name: "Torque",
        calories: "360 Nm@1400-2000 rpm",
      },
      {
        name: "Axle Configuration",
        calories: "4x2",
      },
      {
        name: "Variants",
        calories: "Cabin and chassis or Cabin with load body options",
      },
      {
        name: "Body Length",
        calories: "14ft ; 17ft ; 20ft",
      },
      {
        name: "Warranty",
        calories: "3 Years / 3 Lakh Km on driveline",
      },
      {
        name: "Service Interval",
        calories: "40000 Km (Oil change)",
      },
    ],
    //
  }),
  methods: {
    enquire() {
      router.push("/contact");
      this.$store.state.enquiryname = "Tata Ultra T4";
      (this.promotionDialog = false),
        console.log(this.$store.state.enquiryname);
    },
  },
};
</script>
<style scoped></style>
