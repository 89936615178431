<template>
  <div>
    <v-container>
      <v-row class="justify-space-around">
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card>
            <v-img :src="truck.image"> </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs v-model="tab" background-color="transparent">
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card color="basil" flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions >
                    <v-btn class="primary" @click="truckname=truck.name ; enquire()">Enquire</v-btn>
                    <v-spacer/>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';
export default {
  data: () => ({
    truckname: '',
    trucks: [
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLP%20713%20TMP%2028%20SEATER%204X2%20BUS.webp?alt=media&token=851d5a5f-46e0-49f5-bddb-f12030ef6d80',
        name: "LP 713 TMP 28 SEATER 4X2 BUS",
        enginemake: "TATA",
        enginemodel: "697 TCIC (Euro2)",
        capacity: "5675",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "95 @ 2400",
        torque: "416 @ 1400-1600",
        transmissionm: "Tata – G 40",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "5",
        firstgear: "7.51",
        topgear: "1",
        height: "2900",
        length: "7125",
        width: "2180",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "3800",
        ca: "",
        clearance: "235mm",
        gvm: "7700",
        v: "7700",
        gcm: "9700",
        dt: "9700",
        ppayload: "2740 (Payload only)",
        tare: "4960",
        prear: "4800",
        pfront: "2900",
        manurear: "4800",
        manufront: "2900",
        clutch: "Single",
        diameter: "310",
        reductype: "Single",
        finalr: "3.44",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "",
        fuel: "120",
        tyresize: "7.50R16-14PR",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPO%20918%2038%20SEATER%204X2%20BUS.webp?alt=media&token=de5974d8-6755-4ff3-932c-a6b06fba4360',
        name: "LPO 918 38 SEATER 4X2 BUS",
        enginemake: "TATA Cummins",
        enginemodel: "	B 180 20 (Euro 2)",
        capacity: "5883",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "130 @ 2500",
        torque: "650 @ 1500",
        transmissionm: "Tata – GBS 600",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "3100",
        length: "8686",
        width: "2300",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "4200",
        ca: "",
        clearance: "",
        gvm: "9500",
        v: "9500",
        gcm: "11500",
        dt: "11500",
        ppayload: "3330 (Payload only)",
        tare: "6170",
        prear: "5700",
        pfront: "4200",
        manurear: "5700",
        manufront: "4200",
        clutch: "Single",
        diameter: "352",
        reductype: "Single",
        finalr: "5.285",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "",
        fuel: "160",
        tyresize: "8.25R20-14PR",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FLPO%201823%2066%20SEATER%204X2%20BUS.webp?alt=media&token=68a95668-99e5-497e-8b6d-b78a42f2b323',
        name: "LPO 1823 66 SEATER 4X2 BUS",
        enginemake: "TATA Cummins",
        enginemodel: "6BT AA 5.9 – 230",
        capacity: "5883",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "173 @ 2500",
        torque: "800 @ 1500",
        transmissionm: "Tata – GBS 750",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "6",
        firstgear: "6.58",
        topgear: "0.77",
        height: "3400",
        length: "12000",
        width: "2600",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "6200",
        ca: "",
        clearance: "",
        gvm: "17900",
        v: "17900",
        gcm: "21000",
        dt: "21000",
        ppayload: "7590 (Payload only)",
        tare: "10310",
        prear: "9000",
        pfront: "7700",
        manurear: "10200",
        manufront: "7700",
        clutch: "Single",
        diameter: "380",
        reductype: "Single",
        finalr: "5.8547",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "",
        fuel: "350",
        tyresize: "315/80R22.5",
      },
      
    ],
    tab: null,
    //
  }),
  methods: {
    enquire() {
      if (this.truckname!='') {
        router.push('/contact')
        this.$store.state.enquiryname = this.truckname
        console.log(this.$store.state.enquiryname)
        }

    
    }
  },
};
</script>

<style></style>
