import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        themes: {
          light: {
            primary: '#1072BA',
            secondary: '#F4AC45',
            accent: '#7DCE82',
            error: '#A61C3C',
          },
        },
      },
});
