<template>
  <div>
    <v-container>
      <v-row class="justify-space-around">
        <v-col
          lg="3"
          md="4"
          sm="6"
          xs="12"
          v-for="truck in trucks"
          :key="truck"
        >
          <v-card>
            <v-img :src="truck.image"> </v-img>
            <v-card-title style="word-break: break-word"> {{ truck.name }} </v-card-title>

            <v-dialog transition="dialog-bottom-transition" max-width="600">
              <template v-slot:activator="{ on, attrs }">
                <v-card-actions>
                  <v-btn color="primary" v-bind="attrs" v-on="on"
                    >Vehicle Specifications</v-btn
                  >
                </v-card-actions>
              </template>
              <template v-slot:default="dialog">
                <v-card>
                  <v-toolbar color="primary" dark class="font-weight-black">{{
                    truck.name
                  }}</v-toolbar>
                  <v-tabs v-model="tab" background-color="transparent">
                    <v-tab> Engine </v-tab>
                    <v-tab> Transmission </v-tab>
                    <v-tab> Major Dimensions </v-tab>
                    <v-tab> Mass </v-tab>
                    <v-tab> Clutch </v-tab>
                    <v-tab> Drive Axle </v-tab>
                    <v-tab> Breaks </v-tab>
                    <v-tab> Electrical & Fuel </v-tab>
                    <v-tab> Tyres </v-tab>
                  </v-tabs>

                  <v-tabs-items v-model="tab">
                    <!-- engine -->
                    <v-tab-item>
                      <v-card color="basil" flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Engine Make :</td>
                              <td>{{ truck.enginemake }}</td>
                            </tr>
                            <tr>
                              <td>Engine Model :</td>
                              <td>{{ truck.enginemodel }}</td>
                            </tr>
                            <tr>
                              <td>Capacity :</td>
                              <td>{{ truck.capacity }}</td>
                            </tr>
                            <tr>
                              <td>Layout :</td>
                              <td>{{ truck.layout }}</td>
                            </tr>
                            <tr>
                              <td>Engine Type :</td>
                              <td>{{ truck.enginetype }}</td>
                            </tr>
                            <tr>
                              <td>Power KW @ r/min :</td>
                              <td>{{ truck.powerkw }}</td>
                            </tr>
                            <tr>
                              <td>Torque NM @ r/min :</td>
                              <td>{{ truck.torque }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- transmission -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Transmission Make :</td>
                              <td>{{ truck.transmissionm }}</td>
                            </tr>
                            <tr>
                              <td>Transmission Type :</td>
                              <td>{{ truck.transmissiont }}</td>
                            </tr>
                            <tr>
                              <td>Shift :</td>
                              <td>{{ truck.shift }}</td>
                            </tr>
                            <tr>
                              <td>No. of gears forward :</td>
                              <td>{{ truck.gears }}</td>
                            </tr>
                            <tr>
                              <td>First gear ration :</td>
                              <td>{{ truck.firstgear }}</td>
                            </tr>
                            <tr>
                              <td>Top gear ratio :</td>
                              <td>{{ truck.topgear }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- major dimensions -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Overall height :</td>
                              <td>{{ truck.height }}</td>
                            </tr>
                            <tr>
                              <td>Overall length :</td>
                              <td>{{ truck.length }}</td>
                            </tr>
                            <tr>
                              <td>Max width :</td>
                              <td>{{ truck.width }}</td>
                            </tr>
                            <tr>
                              <td>Front overhang :</td>
                              <td>{{ truck.frontoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Rear overhang :</td>
                              <td>{{ truck.rearoverhang }}</td>
                            </tr>
                            <tr>
                              <td>Wheelbase :</td>
                              <td>{{ truck.wheelbase }}</td>
                            </tr>
                            <tr>
                              <td>CA – cab to bogie/ rear axle (mm) :</td>
                              <td>{{ truck.ca }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- mass -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>GVM (kg) :</td>
                              <td>{{ truck.gvm }}</td>
                            </tr>
                            <tr>
                              <td>V (kg) :</td>
                              <td>{{ truck.v }}</td>
                            </tr>
                            <tr>
                              <td>GCM (kg) :</td>
                              <td>{{ truck.gcm }}</td>
                            </tr>
                            <tr>
                              <td>D/T (kg) :</td>
                              <td>{{ truck.dt }}</td>
                            </tr>
                            <tr>
                              <td>Permissible body & payload (kg) :</td>
                              <td>{{ truck.ppayload }}</td>
                            </tr>
                            <tr>
                              <td>Tare – total (T) (kg) :</td>
                              <td>{{ truck.tare }}</td>
                            </tr>
                            <tr>
                              <td>Permissible rear axle weight (kg) :</td>
                              <td>{{ truck.prear }}</td>
                            </tr>
                            <tr>
                              <td>Permissible front axle weight (kg) :</td>
                              <td>{{ truck.pfront }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers rear axle weight (kg) :</td>
                              <td>{{ truck.manurear }}</td>
                            </tr>
                            <tr>
                              <td>Manufacturers front axle weight (kg) :</td>
                              <td>{{ truck.manufront }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- clutch -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Clutch type :</td>
                              <td>{{ truck.clutch }}</td>
                            </tr>
                            <tr>
                              <td>Diameter (mm) :</td>
                              <td>{{ truck.diameter }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Drive axel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Reduction type :</td>
                              <td>{{ truck.reductype }}</td>
                            </tr>
                            <tr>
                              <td>Final ratio :</td>
                              <td>{{ truck.finalr }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Brakes -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Brakes type :</td>
                              <td>{{ truck.brakestype }}</td>
                            </tr>
                            <tr>
                              <td>Front :</td>
                              <td>{{ truck.bfront }}</td>
                            </tr>
                            <tr>
                              <td>Rear :</td>
                              <td>{{ truck.brear }}</td>
                            </tr>
                            <tr>
                              <td>Anti-lock braking-system (ABS) :</td>
                              <td>{{ truck.antilock }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Electrical and Fuel -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Fuel tank capacity (Ltrs) :</td>
                              <td>{{ truck.fuel }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                    <!-- Tyres -->
                    <v-tab-item>
                      <v-card flat>
                        <v-card-text>
                          <table width="100%">
                            <tr>
                              <td>Size & ply rating :</td>
                              <td>{{ truck.tyresize }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </v-tab-item>
                  </v-tabs-items>
                  <v-card-actions>
                    <v-btn class="primary" @click="truckname=truck.name ; enquire()">Enquire</v-btn>
                    <v-spacer/>
                    <v-btn text @click="dialog.value = false">Close</v-btn>
                  </v-card-actions>
                </v-card>
              </template>
            </v-dialog>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import router from '@/router';
export default {
  data: () => ({
    truckname: '',
    trucks: [
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FPrima%202528K%20%E2%80%93%2010%20cube%20tipper.webp?alt=media&token=110b5972-f5f6-4850-b831-388bb4647e8c',

        name: "Prima 2528K – 10 cube tipper",
        enginemake: "TATA Cummins",
        enginemodel: "ISBe Euro III",
        capacity: "6700",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "198 @ 2500",
        torque: "970 @ 1200-1700",
        transmissionm: "ZF – 9S1110DD",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "12.73",
        topgear: "1",
        height: "3486",
        length: "8337",
        width: "2590",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "4570",
        ca: "3850",
        gvm: "25500",
        v: "",
        gcm: "",
        dt: "",
        ppayload: "",
        tare: "8455",
        prear: "7500",
        pfront: "18000",
        manurear: "7500",
        manufront: "18000",
        clutch: "Single",
        diameter: "430",
        reductype: "Single",
        finalr: "5.86",
        brakestype: "Full dual circuit air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "No",
        fuel: "260",
        tyresize: "315/80R22.5 Rib at rear and lug at front",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2Fprima-LX-25T-gallery-03.webp?alt=media&token=ad004aa8-9b77-4c9e-88d1-98996ed6dfd2',

        name: "Prima 2538K 10 Cube tipper",
        enginemake: "TATA Cummins",
        enginemodel: "ISLe 375 Euro III",
        capacity: "8850",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "276 @ 2500",
        torque: "2550 @ 1200-1400",
        transmissionm: "Eaton FSO 10309 OD",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "10.58 (Crawler)",
        topgear: "0.74",
        height: "3610",
        length: "8337",
        width: "2600",
        frontoverhang: "1482",
        rearoverhang: "1560",
        wheelbase: "4570",
        ca: "3850",
        clearance: "na",
        gvm: "25500",
        v: "25500",
        gcm: "",
        dt: "",
        ppayload: "15630",
        tare: "9870",
        prear: "18000",
        pfront: "7500",
        manurear: "18000",
        manufront: "7500",
        clutch: "Single",
        diameter: "430",
        reductype: "Hub",
        finalr: "5.492",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "2 Stage Jacobs",
        fuel: "260",
        tyresize: "315/80R22.5 Rib at rear and lug at front",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FPrima%203338K%20%E2%80%93%208%C3%974%20Tipper.webp?alt=media&token=16dcb5f0-f683-4c20-a7fa-5d725c28aaa1',
        name: "Prima 3338K – 8×4 Tipper",
        enginemake: "TATA Cummins",
        enginemodel: "ISLe Euro III",
        capacity: "8850",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "276 @ 2500",
        torque: "1550 @ 1200-1400",
        transmissionm: "Eaton – ES15409DD",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "14.39 (Crawler)",
        topgear: "1",
        height: "4218",
        length: "9260",
        width: "2600",
        frontoverhang: "",
        rearoverhang: "",
        wheelbase: "5250",
        ca: "4525",
        clearance: "",
        gvm: "33000",
        v: "",
        gcm: "",
        dt: "",
        ppayload: "",
        tare: "11220 without body",
        prear: "18000",
        pfront: "15000",
        manurear: "18000",
        manufront: "15000",
        clutch: "Single",
        diameter: "430",
        reductype: "Hub",
        finalr: "7.246",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "2 Stage Jacobs",
        fuel: "260",
        tyresize: "12R24(On/Off road tyres at front and rear)",
      },
      {
        image : 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FPrima%204038%204%C3%972%20truck%20tractor.webp?alt=media&token=8e81157c-97a2-43f4-bd16-1a9793d533d3',
        name: "Prima 4038 4×2 truck tractor.",
        enginemake: "TATA Cummins",
        enginemodel: "ISLe Euro III",
        capacity: "8850",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "276 @ 2500",
        torque: "1550 @ 1200-1400",
        transmissionm: "Eaton – FSO10309OD",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "10.58",
        topgear: "0.74",
        height: "3286",
        length: "6133",
        width: "2950",
        frontoverhang: "1465",
        rearoverhang: "1191",
        wheelbase: "3460",
        ca: "2262",
        clearance: "",
        gvm: "18000",
        v: "18000",
        gcm: "40500",
        dt: "40500",
        ppayload: "Truck Tractor",
        tare: "7450",
        prear: "9000",
        pfront: "7500",
        manurear: "9000",
        manufront: "7500",
        clutch: "Single",
        diameter: "430",
        reductype: "Hub",
        finalr: "5.126",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "",
        fuel: "625",
        tyresize: "315/80R22.5 Rib at front – lug at rear",
      },
      {
        image: 'https://firebasestorage.googleapis.com/v0/b/tatanw-dd955.appspot.com/o/trucks%2FPRIMA%204938%20%E2%80%93%206%C3%974%20truck%20tractor.webp?alt=media&token=d9212f32-3fd8-4426-9da2-561fe9235926',
        name: "PRIMA 4938 – 6×4 truck tractor",
        enginemake: "TATA Cummins",
        enginemodel: "ISLe 375 Euro III",
        capacity: "8850",
        layout: "Inline 6",
        enginetype: "Diesel Turbo intercooled",
        powerkw: "276 @ 2500",
        torque: "1550 @ 1200-1400",
        transmissionm: "Eaton – FSO10309OD",
        transmissiont: "Syncromesh",
        shift: "Manual",
        gears: "9",
        firstgear: "10.58",
        topgear: "0.74",
        height: "3286",
        length: "6968",
        width: "2950",
        frontoverhang: "1482",
        rearoverhang: "921",
        wheelbase: "3890",
        ca: "5695",
        clearance: "",
        gvm: "25500",
        v: "25500",
        gcm: "49500",
        dt: "49500",
        ppayload: "",
        tare: "9970",
        prear: "18000",
        pfront: "7500",
        manurear: "18000",
        manufront: "7500",
        clutch: "Single",
        diameter: "430",
        reductype: "Hub",
        finalr: "5.492",
        brakestype: "Full dual air",
        bfront: "Drums",
        brear: "Drums",
        antilock: "Standard",
        EngineBreak: "2 Stage Jacobs",
        fuel: "650",
        tyresize: "315/80R22.5 Rib at front – lug at rear",
      },
    ],
    tab: null,
    //
  }),
  methods: {
    enquire() {
      if (this.truckname!='') {
        router.push('/contact')
        this.$store.state.enquiryname = this.truckname
        console.log(this.$store.state.enquiryname)
        }

    
    }
  },
};
</script>

<style></style>
