import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import MedCom from "../views/Trucks/MedCom.vue";
import HeavyCom from "../views/Trucks/HeavyCom.vue";
import BusRange from "../views/Trucks/BusRange.vue";
import DaewooRange from "../views/Trucks/DaewooRange.vue";
import PrimaRange from "../views/Trucks/PrimaRange.vue";
import EHeavyCom from "../views/Trucks/EHeavyCom.vue";
import Meta from 'vue-meta'

Vue.use(VueRouter);
Vue.use(Meta)

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/models",
    name: "models",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ModelsView.vue"),
    children: [
      { path: "medcom", component: MedCom },
      { path: "heavycom", component: HeavyCom },
      { path: "eheavycom", component: EHeavyCom },
      { path: "primarange", component: PrimaRange },
      { path: "busrange", component: BusRange },
      { path: "daewoorange", component: DaewooRange },
    ],
  },
  {
    path: "/promotions",
    name: "promotions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PromotionsView.vue"),
  },
  {
    path: "/usedvehicles",
    name: "usedvehicles",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/UsedView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactView.vue"),
  },
  {
    path: "/aftersales",
    name: "aftersales",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AftersalesView.vue"),
  },
  {
    path: "/promotions",
    name: "promotions",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/PromotionsView.vue"),
  },
];


const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  
})
router.beforeEach(function (to, from, next) { 
  setTimeout(() => {
      window.scrollTo(0, 0);
  }, 100);
  next();
});


export default router;
